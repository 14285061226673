var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"close-on-content-click":false,"width":"290px"},on:{"click:outside":function($event){_vm.readOnly = false}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{class:("date-picker-field-normal " + (_vm.field.dense ? 'dense' : ''))},[_c('div',{class:{
      'v-input theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined v-text-field--placeholder date-picker-field': true,
      'v-input--dense dense': _vm.field.dense,
      'v-input--is-disabled': typeof _vm.field.disabled === 'boolean' ? _vm.field.disabled : _vm.field.disabled(),
      'error--text v-input--has-state': _vm.getError() && _vm.getError().has,
      'required': _vm.field.required
    }},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('fieldset',{attrs:{"aria-hidden":"true"}},[_c('legend',[_c('span',[_vm._v("​")])])]),_c('div',{staticClass:"v-text-field__slot"},[_c('label',{class:{
            'v-label v-label--active theme--light': true,
            'error--text': _vm.getError() && _vm.getError().has
          },staticStyle:{"left":"0px","right":"auto","position":"absolute"},domProps:{"textContent":_vm._s(_vm.field.title)}}),_c('input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.getVersion() === 'no' ? /\d{2}-\d{2}-\d{4}/ : /\d{4}-\d{2}-\d{2}/),expression:"getVersion() === 'no' ? /\\d{2}-\\d{2}-\\d{4}/ : /\\d{4}-\\d{2}-\\d{2}/"},{name:"model",rawName:"v-model",value:(_vm.previewValue),expression:"previewValue"}],attrs:{"autocomplete":"off","placeholder":_vm.getVersion() === 'no' ? 'dd-mm-yyyy' : 'yyyy-mm-dd',"type":"text"},domProps:{"value":(_vm.previewValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.previewValue=$event.target.value}}})]),_c('div',{staticClass:"v-input__append-inner"},[_c('v-btn',_vm._g({attrs:{"small":_vm.field.dense,"icon":""},on:{"mousedown":function($event){_vm.readOnly = true}}},on),[_c('v-icon',[_vm._v("mdi-calendar")])],1)],1)]),_c('div',{staticClass:"v-text-field__details"},[(_vm.getError().has)?_c('div',{staticClass:"v-messages theme--light error--text"},[_c('div',{staticClass:"v-messages__wrapper"},_vm._l((_vm.getError().messages),function(message){return _c('div',{staticClass:"v-messages__message"},[_vm._v(_vm._s(message))])}),0)]):_vm._e()])])])])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"color":"primary","scrollable":"","show-current":"","show-week":"","min":_vm.min,"max":_vm.max,"disabled":typeof _vm.field.disabled === 'boolean' ? _vm.field.disabled : _vm.field.disabled(),"first-day-of-week":1,"locale-first-day-of-year":_vm.getLocaleFirstDayOfYear()},model:{value:(_vm.datePickerValue),callback:function ($$v) {_vm.datePickerValue=$$v},expression:"datePickerValue"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }