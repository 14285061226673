






























































import { Component, Prop, Watch } from 'vue-property-decorator'
import AbstractField from '@/shared/classes/form/fields/abstract-field'
import _ from 'lodash'
import __ from '@/shared/helpers/__'
import moment from 'moment'
// @ts-ignore
import mask from 'vue-r-mask'
import { getVersion } from '@/shared/helpers/version.helper'

@Component({
  methods: { __, getVersion },
  directives: { mask }
})
export default class DatePickerFieldNormal extends AbstractField {
  @Prop() classes!: string[]
  datePickerValue: string = ''
  menuItem: boolean = false
  date: { [property: string]: string } = { year: '', month: '', day: '' }
  readOnly: boolean = false
  insideChange: boolean = false
  previewValue: string = ''

  @Watch('previewValue') private handlePreviewValueChange(): void {
    if (!this.previewValue) return this.processValueChange(this.previewValue)

    const lt = moment(this.previewValue, 'YYYY-MM-DD', true)
    const no = moment(this.previewValue, 'DD-MM-YYYY', true)

    if (lt.isValid() || no.isValid()) {
      let date = lt.format('YYYY-MM-DD')
      if (no.isValid()) date = no.format('YYYY-MM-DD')

      this.value = date
      this.datePickerValue = date
      this.processValueChange(date)

      this.removeError()
      if (this.field.onChange) this.field.onChange(date, this.fullKey)
    }
  }

  @Watch('datePickerValue') private handleDatePickerChange(): void {
    this.value = this.datePickerValue
    this.processValueChange(this.datePickerValue)

    this.menu = false
  }

  @Watch('value') private handleValueChange(): void {
    if (!this.value) this.processValueChange(this.value)
  }

  created() {
    this.processValueChange(this.value)
  }

  processValueChange(value: any) {
    const lt = moment(value, 'YYYY-MM-DD', true)
    const no = moment(value, 'DD-MM-YYYY', true)

    if (! lt.isValid() && ! no.isValid()) {
      this.value = null
      this.previewValue = ''
      this.datePickerValue = ''

      return
    }

    const correctFormat = lt.isValid() ? lt : no

    this.previewValue = correctFormat.format(getVersion() === 'no' ? 'DD-MM-YYYY' : 'YYYY-MM-DD')
    this.datePickerValue = correctFormat.format('YYYY-MM-DD')
  }

  get menu(): boolean {
    return this.menuItem
  }

  set menu(value: boolean) {
    this.menuItem = value
  }

  onClose(): void {
    this.menu = false
  }

  get min(): string | null {
    if (this.field.meta) {
      if (this.field.meta.hasOwnProperty('minKey')) {
        const fullKey = this.fullKey.split('.')
            .filter((value: string) => value !== this.field.key)
            .join('.')
        return moment(_.get(this.form.data, this.field.meta.minKey(fullKey), null), 'YYYY-MM-DD').format('YYYY-MM-DD')
      }

      if (this.field.meta.hasOwnProperty('min')) {
        return this.field.meta.min
      }
    }

    return null
  }

  getLocaleFirstDayOfYear(): number {
    return moment().startOf('year').day() - 1;
  }

  get max(): string | null {
    if (this.field.meta) {
      if (this.field.meta.hasOwnProperty('maxKey')) {
        const fullKey = this.fullKey.split('.')
            .filter((value: string) => value !== this.field.key)
            .join('.')
        return moment(_.get(this.form.data, this.field.meta.maxKey(fullKey), null), 'YYYY-MM-DD').format('YYYY-MM-DD')
      }

      if (this.field.meta.hasOwnProperty('max')) {
        return this.field.meta.max
      }
    }

    return null
  }
}

